<template>
 <div>
        <v-row dense>
            <v-toolbar dense  class="mb-3">
                   <v-spacer/><span style="color:green;">PLAY RNG </span><v-spacer/>
               </v-toolbar>
            <v-col cols="12" class="pa-3">
             
              <v-row no-gutters class="text-primary">
                 <span class="mt-2">{{$moment(selected_draw.date).format("MMM DD, YYYY")}} {{selected_draw.time}}</span>
                 <v-spacer/>
                 <span style="width: 140px" v-if="OPEN_DRAWS.length !=0">
                    <v-select
                      dense
                      v-model="selected_draw"
                      outlined
                      label="Draw Time"
                      item-text="time"
                      :items="[OPEN_DRAWS[0]]"
                      return-object
                    />
                
                 </span>
              </v-row>
              <v-row  v-if="$store.state.watchdraw==true">
                <v-col cols="12" class="pa-2">
                  <v-btn color="success" x-large @click="setOpenMachine(true)" block> <v-icon color="error" left>mdi-play-circle</v-icon> watch Draw</v-btn>
                </v-col>
              </v-row>
                  <table class="va-table print_table3 mt-2 text-left" cellspacing="0">
                      <tr>
                          <td class="pa-1 md-font " style="background: #eaeaea;">COMBINATION</td>
                          <td class="text-center pa-1 md-font " style="background: #eaeaea;">PLAY</td>
                          <td class="text-right pa-1 md-font " style="background: #eaeaea;">AMOUNT</td>
                      </tr>
                      <tr v-for="(item, i) in Lines" :key="i">
                          <td><v-icon left small color="error" @click="removeline(i)">mdi-close</v-icon> {{item.num1}} - {{item.num2}}</td>
                          <td class="text-center">{{item.line_type}}</td>
                          <td class="text-right">{{$money(item.line_amount)}}</td>
                      </tr>
                  </table>
                  <br />
                  
                  <v-row no-gutters class="text-primary">
                    <p class="sm-font" :class="BALANCE<1?'text-error':'text-info'">UPDATING BALANCE: {{$money(BALANCE)}}</p>
                    <v-spacer/>
                    <h4>TOTAL:  {{$money(TOTAL)}}</h4>
                  </v-row>
                   
            </v-col>
        </v-row>
        
                  <v-bottom-navigation  grow class="elevation-0 mt-5">
                    <v-btn value="clear" @click="clear()">
                      <span class="text-error">CLEAR</span>
                      <v-icon  color="error">mdi-cancel</v-icon>
                    </v-btn>

                    <v-btn @click="numpad=true">
                      <span class="text-secondary">ADD BET</span>
                      <v-icon color="secondary">mdi-plus</v-icon>
                    </v-btn>

                    <v-btn value="nearby" @click="ValidateTicket()">
                      <span class="text-success">PROCEED</span>
                      <v-icon color="success">mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-bottom-navigation>
      <va-numpad :show="numpad" @DialogEvent="numpadEvent"/>
        <va-ticket-preview :show="preview" :lines="validated_lines" :ticket="ticket" @DialogEvent="previewEvent"/>
         <va-ticket :show="ticket_dailog" :ticket="ticket" @DialogEvent="ticketEvent"/>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      drform: false,
      numpad: false,
      preview: false,
      ticket_dailog: false,
      selected_draw:{},
      Lines:[],
      validated_lines:[],
      ticket:{}
    }),
    created() {
      this.PING()
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
             this.GET_OPEN_GAMES("2D")
          this.CHECK_BALANCE(this.user.id)
          setTimeout(()=>{
            this.selected_draw = this.$store.state.open_draws[0]
          }, 500)
        }
    },
    computed:{
       user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      OPEN_DRAWS() {
       return this.$store.state.open_draws
      },
      TOTAL() {
           return this.Lines.reduce((res,item)=>res += this.$IsNum(item.line_amount), 0)
      },
      BALANCE(){
        return  this.$store.state.balance.balance - this.TOTAL
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', "setOpenMachine"]),
      previewEvent(e){
        this.preview = false
        if(e != 'cancel') {
          if(e.status) {
            this.ticket = e.ticket
            this.ticket_dailog = true
            this.CHECK_BALANCE(this.user.id)
          } else {
              this.VA_ALERT("error", e.message)
          }
        }
      },
      ticketEvent(e){
        this.ticket_dailog = false
        if(e=="done"){
            this.clear()
        }
        
      },
      ValidateTicket() {
        if(this.Lines.length == 0) {
           this.VA_ALERT("error", "Select your combination!")
          return 
        }
        var ticket = {}
        ticket.Lines = this.Lines
        ticket.amount = this.TOTAL.toString()
        ticket.game="2D"
        ticket.draw_date = this.selected_draw.date + " " + this.selected_draw.time
        ticket.draw_id = this.selected_draw.id
        this.ValidateNewTicket(ticket)
      },
      clear(){
        this.Lines=[]
          this.ticket={}
      },
      ValidateNewTicket(ticket){
        this.validated_lines=[]
         this.$http.post("ticket/validate_new", ticket).then(response => {
               
                if( response.data.status) {
                  this.validated_lines = response.data.newlines
                   this.ticket = ticket
                  this.preview = true
                } else {
                  this.VA_ALERT('error', response.data.message )
                }
               }).catch(e => {
                console.log(e.data)
            })
      },
      removeline(i){
        this.Lines.splice(i,1)
      },
      numpadEvent(data){
        console.log(data)
        console.log(this.Lines)
        this.numpad = false
        if(data != "cancel"){
          this.Lines.push(data)
        }
        console.log(this.Lines)
      }
    },
  }
</script>